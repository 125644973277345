html {
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

#root {
  height: 100vh;

  font-family: 'TTNorms-Regular', Helvetica, sans-serif;
  color: #535353;
}

.App {
  height: 100vh;
}

.recaptcha-container > div > div > div{
  margin: 0px auto !important;
}

.consumer-validation-modal .btn.btn-success {
  background: #71289a;
  border: 1px solid #71289a;
  color: #ffffff;
}

.consumer-validation-modal .btn.btn-link {
  color: #f19800;
}

.modal-content {
  border: none;
}

/* Footer */

footer {
  background-color: #999;
}

footer p {
  font-size: 0.75rem;
  line-height: 140%;
}

footer a:link {
  font-size: 0.75rem;
  font-weight: bold;
  font-weight: bold;
  text-decoration: none;
}

/* Footer */