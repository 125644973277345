.layout {
  display: -webkit-box;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.layout-grey {
  background: #e9eaed;
}

.layout-grey .content {
  background: #e9eaed;
}

.layout-purple {
  background: #46196e;
}

.layout-lightpurple {
  background: #9646c3;
}

.layout-otherpurple {
  background: #9669a9;
}

.layout-deeppurple {
  background: #24002f;
}

.offersAvailableHeader {
  color: #ffffff;
  background: #fa9d28;
  font-size: 14px;
  font-family: 'TTNorms-Regular';
}
.offersAvailableFooter {
  background: #ffffff;
}
.offersAvailableRow {
  color: black;
  background: #ffffff;
  font-size: 14px;
  font-family: 'TTNorms-Bold';
  border-bottom-color: #cacaca !important;
  border-bottom-width: thin !important;
}

.offersRedeemedHeader {
  color: #24002f;
  background: #9669a9;
  font-size: 14px;
  font-family: 'TTNorms-Regular';
}
.offersRedeemedFooter {
  background: #9669a9;
}
.offersRedeemedRow {
  color: #ffffff;
  background: #9669a9;
  font-size: 14px;
  font-family: 'TTNorms-Bold';
}
.offersRedeemedRow td {
  border-top: none;
}
.offersRedeemedFooter td {
  border-top: none;
}

.expirationDateCol {
  text-align: center;
  width: 25%;
}

.offerNameCol {
  width: 50%;
}

.offersAvailableLinkCol {
  color: #9646c3 !important;
  cursor: pointer;
}

.offersRedeemedLinkCol {
  color: #fa9d28 !important;
  cursor: pointer;
}

.myOffersTable { border-collapse: separate; border-spacing: 0; }
.myOffersTable tr:first-child th { border-top-color: transparent; }
.myOffersTable tr:first-child th:first-child { border-top-left-radius: 5px; }
.myOffersTable tr:first-child th:last-child { border-top-right-radius: 5px; }
.myOffersTable tr:last-child td:first-child { border-bottom-left-radius: 5px; }
.myOffersTable tr:last-child td:last-child { border-bottom-right-radius: 5px; }

.content {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  flex-direction: row;
  border-top: #ffffff;
  border-top-width: thin;
  border-top-style: solid;
  margin-top: -1px;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.scrollbar
{
  background: #ffffff;
  overflow-y: auto;
  margin-bottom: 20px;
  max-height: 500px;
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar
{
  width: 12px;
}

.scrollbar::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
}

.chat-container {
  position: fixed;
  bottom: 0;
  right: 10px;
  border: 1px solid #cacaca;
  border-bottom: none;
  background: #ffffff;
  box-shadow: 1px 1px 10px #cacaca;
}

.chat-container > iframe {
  display: inline-block;
  height: 420px;
  border: none;
}

.chat-close {
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 4px;
  cursor: pointer;
}

.upload-file{
  background: #cacaca;
  overflow-y: auto;
  margin: 20px;
  max-height: 550px;
  border-radius: 5px;
  padding:20px;
}

.link-metro {
  color: #ffffff;
  text-decoration: none;
}

.link-metro:hover {
  color: #f89c29;
  text-decoration: none;
}

.link-metro-underline {
  text-decoration: underline;
}
