.titleWhite42Text {
  color: #ffffff;
  font-size: 42px;
  font-family: 'TTNorms-Bold';
  line-height: 42px;
}

.titleWhite24Text {
  color: #ffffff;
  font-size: 24px;
  font-family: 'TTNorms-Bold';
  line-height: 24px;
}

.instructionalWhite14Text {
  color: #ffffff;
  font-size: 14px;
  font-family: 'TTNorms-Regular';
  line-height: 14px;
}

.titleOrange42Text {
  color: #fa9d28;
  font-size: 42px;
  font-family: 'TTNorms-Bold';
  line-height: 42px;
}

.titleOrange24Text {
  color: #fa9d28;
  font-size: 24px;
  font-family: 'TTNorms-Bold';
  line-height: 24px;
}

.requiredFieldsText {
  color: #fa9d28;
  font-size: 11px;
  font-family: 'TTNorms-Regular';
  line-height: 11px;
}

.footerTextBig {
  font-size: 12px;
  font-family: 'TTNorms-Bold';
  line-height: 12px;
}

.footerTextSmall {
  font-size: 10px;
  font-family: 'TTNorms-Regular';
  line-height: 10px;
}

.whiteTextSmall {
  color: #ffffff;
  font-size: 12px;
}

.orangeTextSmall {
  color: #fa9d28;
  font-size: 12px;
}

.baseLineStyle {
  padding-left: 15px;
}

.oneLineStyleSmall {
  line-height: 58px;
  font-size: 58px;
}

.twoLinesStyleSmall {
  line-height: 36px;
  font-size: 36px;
}

.oneLineStyle {
  line-height: 90px;
  font-size: 42px;
}

.twoLinesStyle {
  line-height: 45px;
  font-size: 42px;
}

.oneLineStyleMedium {
  line-height: 120px;
  font-size: 58px;
}

.twoLinesStyleMedium {
  line-height: 60px;
  font-size: 58px;
}

.oneLineStyleTall {
  line-height: 148px;
  font-size: 70px;
}

.twoLinesStyleTall {
  line-height: 74px;
  font-size: 70px;
}
