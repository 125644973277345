.tabs {
  margin: 0px auto;
  /* max-width: 550px; */
  background: white;
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
}

.tabs-menu {
  display: flex;
  max-width: 550px;

  margin-left: 5%;
  padding: 0;

  list-style: none;

  font-size: 10px;
  font-weight: bold;
  text-decoration: underline;

  text-align: center;
}

@media (min-width: 576px) {
  .tabs-menu {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
  }
}

.tabs-menu li {
  flex: 1;
  height: 50px;
  border: none;
  background: #ffffff;
  color: #0000ff;
  cursor: pointer;
}

.tabs-menu li.isActive {
  color: #000000;
}

.tabs-menu li a {
  display: inline-block;
  width: 100%;
  height: 100%;

  text-align: center;
  line-height: 50px;
}

.tabs-menu li div {
  display: inline-block;
  width: 100%;
  height: 100%;

  text-align: center;
  line-height: 50px;
}

.tabs-content {
  padding-right: 24px;
  padding-left: 24px;
}

.tabs-content > div {
  visibility: hidden;
  display: none;
}

.tabs-content > div.isActive {
  visibility: visible;
  display: block;
}