.radio-toggle-fieldset {
  display: flex;
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
}

.radio-toggle-fieldset > * {
  flex: 1 0;
}

.radio-toggle-fieldset > label {
  margin: 0 auto;
  padding: 12px;
  background: #71289a;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
}

.radio-toggle-fieldset > input {
  display: none;
}

.radio-toggle-fieldset > :first-child > label {
  border-radius: 5px 0 0 5px;
}

.radio-toggle-fieldset > :last-child > label {
  border-radius: 0 5px 5px 0;
}

.radio-toggle-fieldset > input:checked + label {
  background: #e37501;
}
