@import url("//hello.myfonts.net/count/37a423");

@font-face {font-family: 'TTNorms-BlackItalic';src: url('/fonts/37A423_0_0.eot');src: url('/fonts/37A423_0_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_0_0.woff2') format('woff2'),url('/fonts/37A423_0_0.woff') format('woff'),url('/fonts/37A423_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ExtraBoldItalic';src: url('/fonts/37A423_1_0.eot');src: url('/fonts/37A423_1_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_1_0.woff2') format('woff2'),url('/fonts/37A423_1_0.woff') format('woff'),url('/fonts/37A423_1_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Black';src: url('/fonts/37A423_2_0.eot');src: url('/fonts/37A423_2_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_2_0.woff2') format('woff2'),url('/fonts/37A423_2_0.woff') format('woff'),url('/fonts/37A423_2_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-BoldItalic';src: url('/fonts/37A423_3_0.eot');src: url('/fonts/37A423_3_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_3_0.woff2') format('woff2'),url('/fonts/37A423_3_0.woff') format('woff'),url('/fonts/37A423_3_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Bold';src: url('/fonts/37A423_4_0.eot');src: url('/fonts/37A423_4_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_4_0.woff2') format('woff2'),url('/fonts/37A423_4_0.woff') format('woff'),url('/fonts/37A423_4_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ExtraBold';src: url('/fonts/37A423_5_0.eot');src: url('/fonts/37A423_5_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_5_0.woff2') format('woff2'),url('/fonts/37A423_5_0.woff') format('woff'),url('/fonts/37A423_5_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ExtraLightItalic';src: url('/fonts/37A423_6_0.eot');src: url('/fonts/37A423_6_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_6_0.woff2') format('woff2'),url('/fonts/37A423_6_0.woff') format('woff'),url('/fonts/37A423_6_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ExtraLight';src: url('/fonts/37A423_7_0.eot');src: url('/fonts/37A423_7_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_7_0.woff2') format('woff2'),url('/fonts/37A423_7_0.woff') format('woff'),url('/fonts/37A423_7_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-HeavyItalic';src: url('/fonts/37A423_8_0.eot');src: url('/fonts/37A423_8_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_8_0.woff2') format('woff2'),url('/fonts/37A423_8_0.woff') format('woff'),url('/fonts/37A423_8_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Heavy';src: url('/fonts/37A423_9_0.eot');src: url('/fonts/37A423_9_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_9_0.woff2') format('woff2'),url('/fonts/37A423_9_0.woff') format('woff'),url('/fonts/37A423_9_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Italic';src: url('/fonts/37A423_A_0.eot');src: url('/fonts/37A423_A_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_A_0.woff2') format('woff2'),url('/fonts/37A423_A_0.woff') format('woff'),url('/fonts/37A423_A_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-LightItalic';src: url('/fonts/37A423_B_0.eot');src: url('/fonts/37A423_B_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_B_0.woff2') format('woff2'),url('/fonts/37A423_B_0.woff') format('woff'),url('/fonts/37A423_B_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Light';src: url('/fonts/37A423_C_0.eot');src: url('/fonts/37A423_C_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_C_0.woff2') format('woff2'),url('/fonts/37A423_C_0.woff') format('woff'),url('/fonts/37A423_C_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-MediumItalic';src: url('/fonts/37A423_D_0.eot');src: url('/fonts/37A423_D_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_D_0.woff2') format('woff2'),url('/fonts/37A423_D_0.woff') format('woff'),url('/fonts/37A423_D_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Medium';src: url('/fonts/37A423_E_0.eot');src: url('/fonts/37A423_E_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_E_0.woff2') format('woff2'),url('/fonts/37A423_E_0.woff') format('woff'),url('/fonts/37A423_E_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Regular';src: url('/fonts/37A423_F_0.eot');src: url('/fonts/37A423_F_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_F_0.woff2') format('woff2'),url('/fonts/37A423_F_0.woff') format('woff'),url('/fonts/37A423_F_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ThinItalic';src: url('/fonts/37A423_10_0.eot');src: url('/fonts/37A423_10_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_10_0.woff2') format('woff2'),url('/fonts/37A423_10_0.woff') format('woff'),url('/fonts/37A423_10_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Thin';src: url('/fonts/37A423_11_0.eot');src: url('/fonts/37A423_11_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_11_0.woff2') format('woff2'),url('/fonts/37A423_11_0.woff') format('woff'),url('/fonts/37A423_11_0.ttf') format('truetype');}
 