@import url(//hello.myfonts.net/count/37a423);
@font-face {font-family: 'TTNorms-BlackItalic';src: url('/fonts/37A423_0_0.eot');src: url('/fonts/37A423_0_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_0_0.woff2') format('woff2'),url('/fonts/37A423_0_0.woff') format('woff'),url('/fonts/37A423_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ExtraBoldItalic';src: url('/fonts/37A423_1_0.eot');src: url('/fonts/37A423_1_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_1_0.woff2') format('woff2'),url('/fonts/37A423_1_0.woff') format('woff'),url('/fonts/37A423_1_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Black';src: url('/fonts/37A423_2_0.eot');src: url('/fonts/37A423_2_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_2_0.woff2') format('woff2'),url('/fonts/37A423_2_0.woff') format('woff'),url('/fonts/37A423_2_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-BoldItalic';src: url('/fonts/37A423_3_0.eot');src: url('/fonts/37A423_3_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_3_0.woff2') format('woff2'),url('/fonts/37A423_3_0.woff') format('woff'),url('/fonts/37A423_3_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Bold';src: url('/fonts/37A423_4_0.eot');src: url('/fonts/37A423_4_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_4_0.woff2') format('woff2'),url('/fonts/37A423_4_0.woff') format('woff'),url('/fonts/37A423_4_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ExtraBold';src: url('/fonts/37A423_5_0.eot');src: url('/fonts/37A423_5_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_5_0.woff2') format('woff2'),url('/fonts/37A423_5_0.woff') format('woff'),url('/fonts/37A423_5_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ExtraLightItalic';src: url('/fonts/37A423_6_0.eot');src: url('/fonts/37A423_6_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_6_0.woff2') format('woff2'),url('/fonts/37A423_6_0.woff') format('woff'),url('/fonts/37A423_6_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ExtraLight';src: url('/fonts/37A423_7_0.eot');src: url('/fonts/37A423_7_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_7_0.woff2') format('woff2'),url('/fonts/37A423_7_0.woff') format('woff'),url('/fonts/37A423_7_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-HeavyItalic';src: url('/fonts/37A423_8_0.eot');src: url('/fonts/37A423_8_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_8_0.woff2') format('woff2'),url('/fonts/37A423_8_0.woff') format('woff'),url('/fonts/37A423_8_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Heavy';src: url('/fonts/37A423_9_0.eot');src: url('/fonts/37A423_9_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_9_0.woff2') format('woff2'),url('/fonts/37A423_9_0.woff') format('woff'),url('/fonts/37A423_9_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Italic';src: url('/fonts/37A423_A_0.eot');src: url('/fonts/37A423_A_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_A_0.woff2') format('woff2'),url('/fonts/37A423_A_0.woff') format('woff'),url('/fonts/37A423_A_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-LightItalic';src: url('/fonts/37A423_B_0.eot');src: url('/fonts/37A423_B_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_B_0.woff2') format('woff2'),url('/fonts/37A423_B_0.woff') format('woff'),url('/fonts/37A423_B_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Light';src: url('/fonts/37A423_C_0.eot');src: url('/fonts/37A423_C_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_C_0.woff2') format('woff2'),url('/fonts/37A423_C_0.woff') format('woff'),url('/fonts/37A423_C_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-MediumItalic';src: url('/fonts/37A423_D_0.eot');src: url('/fonts/37A423_D_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_D_0.woff2') format('woff2'),url('/fonts/37A423_D_0.woff') format('woff'),url('/fonts/37A423_D_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Medium';src: url('/fonts/37A423_E_0.eot');src: url('/fonts/37A423_E_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_E_0.woff2') format('woff2'),url('/fonts/37A423_E_0.woff') format('woff'),url('/fonts/37A423_E_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Regular';src: url('/fonts/37A423_F_0.eot');src: url('/fonts/37A423_F_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_F_0.woff2') format('woff2'),url('/fonts/37A423_F_0.woff') format('woff'),url('/fonts/37A423_F_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-ThinItalic';src: url('/fonts/37A423_10_0.eot');src: url('/fonts/37A423_10_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_10_0.woff2') format('woff2'),url('/fonts/37A423_10_0.woff') format('woff'),url('/fonts/37A423_10_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'TTNorms-Thin';src: url('/fonts/37A423_11_0.eot');src: url('/fonts/37A423_11_0.eot?#iefix') format('embedded-opentype'),url('/fonts/37A423_11_0.woff2') format('woff2'),url('/fonts/37A423_11_0.woff') format('woff'),url('/fonts/37A423_11_0.ttf') format('truetype');}
 
html {
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

#root {
  height: 100vh;

  font-family: 'TTNorms-Regular', Helvetica, sans-serif;
  color: #535353;
}

.App {
  height: 100vh;
}

.recaptcha-container > div > div > div{
  margin: 0px auto !important;
}

.consumer-validation-modal .btn.btn-success {
  background: #71289a;
  border: 1px solid #71289a;
  color: #ffffff;
}

.consumer-validation-modal .btn.btn-link {
  color: #f19800;
}

.modal-content {
  border: none;
}

/* Footer */

footer {
  background-color: #999;
}

footer p {
  font-size: 0.75rem;
  line-height: 140%;
}

footer a:link {
  font-size: 0.75rem;
  font-weight: bold;
  font-weight: bold;
  text-decoration: none;
}

/* Footer */
.layout {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.layout-grey {
  background: #e9eaed;
}

.layout-grey .content {
  background: #e9eaed;
}

.layout-purple {
  background: #46196e;
}

.layout-lightpurple {
  background: #9646c3;
}

.layout-otherpurple {
  background: #9669a9;
}

.layout-deeppurple {
  background: #24002f;
}

.offersAvailableHeader {
  color: #ffffff;
  background: #fa9d28;
  font-size: 14px;
  font-family: 'TTNorms-Regular';
}
.offersAvailableFooter {
  background: #ffffff;
}
.offersAvailableRow {
  color: black;
  background: #ffffff;
  font-size: 14px;
  font-family: 'TTNorms-Bold';
  border-bottom-color: #cacaca !important;
  border-bottom-width: thin !important;
}

.offersRedeemedHeader {
  color: #24002f;
  background: #9669a9;
  font-size: 14px;
  font-family: 'TTNorms-Regular';
}
.offersRedeemedFooter {
  background: #9669a9;
}
.offersRedeemedRow {
  color: #ffffff;
  background: #9669a9;
  font-size: 14px;
  font-family: 'TTNorms-Bold';
}
.offersRedeemedRow td {
  border-top: none;
}
.offersRedeemedFooter td {
  border-top: none;
}

.expirationDateCol {
  text-align: center;
  width: 25%;
}

.offerNameCol {
  width: 50%;
}

.offersAvailableLinkCol {
  color: #9646c3 !important;
  cursor: pointer;
}

.offersRedeemedLinkCol {
  color: #fa9d28 !important;
  cursor: pointer;
}

.myOffersTable { border-collapse: separate; border-spacing: 0; }
.myOffersTable tr:first-child th { border-top-color: transparent; }
.myOffersTable tr:first-child th:first-child { border-top-left-radius: 5px; }
.myOffersTable tr:first-child th:last-child { border-top-right-radius: 5px; }
.myOffersTable tr:last-child td:first-child { border-bottom-left-radius: 5px; }
.myOffersTable tr:last-child td:last-child { border-bottom-right-radius: 5px; }

.content {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  display: flex;
  flex-direction: row;
  border-top: #ffffff;
  border-top-width: thin;
  border-top-style: solid;
  margin-top: -1px;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.scrollbar
{
  background: #ffffff;
  overflow-y: auto;
  margin-bottom: 20px;
  max-height: 500px;
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar
{
  width: 12px;
}

.scrollbar::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.75);
}

.chat-container {
  position: fixed;
  bottom: 0;
  right: 10px;
  border: 1px solid #cacaca;
  border-bottom: none;
  background: #ffffff;
  box-shadow: 1px 1px 10px #cacaca;
}

.chat-container > iframe {
  display: inline-block;
  height: 420px;
  border: none;
}

.chat-close {
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 4px;
  cursor: pointer;
}

.upload-file{
  background: #cacaca;
  overflow-y: auto;
  margin: 20px;
  max-height: 550px;
  border-radius: 5px;
  padding:20px;
}

.link-metro {
  color: #ffffff;
  text-decoration: none;
}

.link-metro:hover {
  color: #f89c29;
  text-decoration: none;
}

.link-metro-underline {
  text-decoration: underline;
}

.sk-fading-circle {
  margin: 0px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #f89c29;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

.chat-spinner {
  width: 4em;
  height: 4em;
  top: 185px;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.radio-toggle-fieldset {
  display: flex;
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
}

.radio-toggle-fieldset > * {
  flex: 1 0;
}

.radio-toggle-fieldset > label {
  margin: 0 auto;
  padding: 12px;
  background: #71289a;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
}

.radio-toggle-fieldset > input {
  display: none;
}

.radio-toggle-fieldset > :first-child > label {
  border-radius: 5px 0 0 5px;
}

.radio-toggle-fieldset > :last-child > label {
  border-radius: 0 5px 5px 0;
}

.radio-toggle-fieldset > input:checked + label {
  background: #e37501;
}

/* FAQ Custom styles */

body{
    background-color: #46196e;
}

#faq h1{
    color: #ffffff;
    font-size: 32px;
    font-family: 'TTNorms-Bold';
    line-height: 42px;
    padding: 50px 0px
}

#faq .item{
    margin-bottom: 30px;
    font-size: 16px;
    color: #fa9d28;
    font-family: 'TTNorms-Regular';
    line-height: 20px;
}

#faq h4{
    font-size: 22px;
    color: #fff;
    font-family: 'TTNorms-Bold';
    line-height: 28px;
}

.content-box-metro .upContent {
    overflow: hidden;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /*
    border: 1px solid white;
    */
    font-size: 16px;
    color: #fa9d28;
    font-family: 'TTNorms-Regular';
}

.content-box-metro .upTitle {
    height: 60px;
    line-height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: center;
    align-items: center;
    background: #fa9d28;
    display: flex;
    border-bottom: none;
}

.itemTitle {
    color: #ffffff;
    font-size: 24px;
    font-family: 'TTNorms-Bold';
    line-height: 24px;
}

/* FAQ Custome styles */
.titleWhite42Text {
  color: #ffffff;
  font-size: 42px;
  font-family: 'TTNorms-Bold';
  line-height: 42px;
}

.titleWhite24Text {
  color: #ffffff;
  font-size: 24px;
  font-family: 'TTNorms-Bold';
  line-height: 24px;
}

.instructionalWhite14Text {
  color: #ffffff;
  font-size: 14px;
  font-family: 'TTNorms-Regular';
  line-height: 14px;
}

.titleOrange42Text {
  color: #fa9d28;
  font-size: 42px;
  font-family: 'TTNorms-Bold';
  line-height: 42px;
}

.titleOrange24Text {
  color: #fa9d28;
  font-size: 24px;
  font-family: 'TTNorms-Bold';
  line-height: 24px;
}

.requiredFieldsText {
  color: #fa9d28;
  font-size: 11px;
  font-family: 'TTNorms-Regular';
  line-height: 11px;
}

.footerTextBig {
  font-size: 12px;
  font-family: 'TTNorms-Bold';
  line-height: 12px;
}

.footerTextSmall {
  font-size: 10px;
  font-family: 'TTNorms-Regular';
  line-height: 10px;
}

.whiteTextSmall {
  color: #ffffff;
  font-size: 12px;
}

.orangeTextSmall {
  color: #fa9d28;
  font-size: 12px;
}

.baseLineStyle {
  padding-left: 15px;
}

.oneLineStyleSmall {
  line-height: 58px;
  font-size: 58px;
}

.twoLinesStyleSmall {
  line-height: 36px;
  font-size: 36px;
}

.oneLineStyle {
  line-height: 90px;
  font-size: 42px;
}

.twoLinesStyle {
  line-height: 45px;
  font-size: 42px;
}

.oneLineStyleMedium {
  line-height: 120px;
  font-size: 58px;
}

.twoLinesStyleMedium {
  line-height: 60px;
  font-size: 58px;
}

.oneLineStyleTall {
  line-height: 148px;
  font-size: 70px;
}

.twoLinesStyleTall {
  line-height: 74px;
  font-size: 70px;
}

.tabs {
  margin: 0px auto;
  /* max-width: 550px; */
  background: white;
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
}

.tabs-menu {
  display: flex;
  max-width: 550px;

  margin-left: 5%;
  padding: 0;

  list-style: none;

  font-size: 10px;
  font-weight: bold;
  text-decoration: underline;

  text-align: center;
}

@media (min-width: 576px) {
  .tabs-menu {
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline;
  }
}

.tabs-menu li {
  flex: 1 1;
  height: 50px;
  border: none;
  background: #ffffff;
  color: #0000ff;
  cursor: pointer;
}

.tabs-menu li.isActive {
  color: #000000;
}

.tabs-menu li a {
  display: inline-block;
  width: 100%;
  height: 100%;

  text-align: center;
  line-height: 50px;
}

.tabs-menu li div {
  display: inline-block;
  width: 100%;
  height: 100%;

  text-align: center;
  line-height: 50px;
}

.tabs-content {
  padding-right: 24px;
  padding-left: 24px;
}

.tabs-content > div {
  visibility: hidden;
  display: none;
}

.tabs-content > div.isActive {
  visibility: visible;
  display: block;
}
