/* FAQ Custom styles */

body{
    background-color: #46196e;
}

#faq h1{
    color: #ffffff;
    font-size: 32px;
    font-family: 'TTNorms-Bold';
    line-height: 42px;
    padding: 50px 0px
}

#faq .item{
    margin-bottom: 30px;
    font-size: 16px;
    color: #fa9d28;
    font-family: 'TTNorms-Regular';
    line-height: 20px;
}

#faq h4{
    font-size: 22px;
    color: #fff;
    font-family: 'TTNorms-Bold';
    line-height: 28px;
}

.content-box-metro .upContent {
    overflow: hidden;
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /*
    border: 1px solid white;
    */
    font-size: 16px;
    color: #fa9d28;
    font-family: 'TTNorms-Regular';
}

.content-box-metro .upTitle {
    height: 60px;
    line-height: 30px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    justify-content: center;
    align-items: center;
    background: #fa9d28;
    display: flex;
    border-bottom: none;
}

.itemTitle {
    color: #ffffff;
    font-size: 24px;
    font-family: 'TTNorms-Bold';
    line-height: 24px;
}

/* FAQ Custome styles */